a {
  color: $orange;
}

.body-triggered {
  background: $white;
  transition: 0.001s linear 0.3s;
}

.popup-opened {
  background: #fcdfd8;

  .page-wrapper {
    opacity: 0;
  }
}

.link {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $orange;
    transition: opacity 0.3s ease;
    opacity: 0;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &.active {
    color: $white;
    background: $orange;
  }

  &:active {
    opacity: 1;
  }
}

.container {
  padding-right: 16px;
  padding-left: 16px;
  max-width: 1466px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  box-sizing: border-box;

  @include min768 {
    padding-right: 24px;
    padding-left: 24px;
  }
  @include min1280 {
    padding-right: 40px;
    padding-left: 40px;
  }

  &--large {
    max-width: unset;

    @include min1280 {
      padding-right: 40px;
      padding-left: 40px;
    }
    @include min1440 {
      padding-left: 60px;
      padding-right: 60px;
    }
    @include min1920 {
      padding-left: 80px;
      padding-right: 80px;
    }

    @media (min-width: 2732px) {
      padding-left: 6%;
      padding-right: 6%;
    }
  }

  .container {
    padding-right: 0;
    padding-left: 0;
  }
}

.article-wrapper {
  padding-left: 8px;
  padding-right: 8px;

  @include min768 {
    padding-left: 90px;
    padding-right: 90px;
  }
  @include min1280 {
    padding-left: 228px;
    padding-right: 228px;
  }
}

//для доступности. Когда в секции должен быть заголовок,
// а по дизайну его нет
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);
  left: 1px;
  right: 1px;
}

.mobile {
  display: block;

  @include min768 {
    display: none;
  }
}

.notMobile {
  display: none;

  @include min768 {
    display: block;
  }
}

.tablet {
  display: none;

  @include min768 {
    display: block;
  }
  @include min1280 {
    display: none;
  }
}

.desktop {
  display: none;

  @include min1280 {
    display: block;
  }
}


.outline-text {
  // -webkit-text-stroke-width: 1px;
  // -webkit-text-stroke-color: $orange;
  color: $orange10;
  text-shadow:
    -1px -1px 0 $orange,
    1px -1px 0 $orange,
    -1px 1px 0 $orange,
    1px 1px 0 $orange;
}


.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  footer {
    margin-top: auto;
  }
}


/*
запаска на случай, если надо все карточки в слайдере одинаковые по высоте
.swiper-wrapper .swiper-slide {
  height: auto;

  & > * {
    height: 100%;
  }
}
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: $orange;
  box-shadow: 0 0 0 1000px $pig2 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}

i {
  font-style: normal;
}


.radio-wrapper {

}

.ql-direction-rtl {
  direction: rtl;
}

.ql-align-right {
  text-align: right;
}

li.ql-align-right {
  padding-left: 0 !important;
  padding-right: 32px;

  &::before {
    left: auto!important;
    right: 0;
  }

  @include min1280 {
    padding-right: 40px;
  }
}
.ql-align-center{
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include min768 {
    width: 60%;
    margin: 0 auto;
    max-width: 900px;
  }
}


.webstore-link {
  padding-top: 20px;
  display: block;;
}
.webstore-link img {
  transition: all 0.2s ease;
}

.webstore-link:hover img {
  transform: rotate(5deg) scale(1.05);
}
.big-body {
  font: 400 18px/1.4 $ibm;
  letter-spacing: -0.02rem;

  @include min768 {
    font-size: 20px;
    line-height: 1.5;

    @media (max-height: 600px) {
      font-size: 16px;
    }
  }
  @include min1440 {
    font-size: 24px;
  }
}

.big-h1 {
  font: 700 48px/1.1 $hagrid;
  letter-spacing: -0.04rem;

  @include min768 {
    font-size: 88px;
    line-height: 1;

    @media (max-height: 600px) {
      font-size: 88px * 0.8;
      line-height: 1.2;
    }
  }
  @include min1024 {
    font-size: 104px;
  }
  @include min1280 {
    font-size: 124px;
  }
  @include min1440 {
    font-size: 136px;
  }
  @include outsideContainer {
    font-size: 144px;
  }
}

h1, .h1 {
  font: 700 42px/1.1 $hagrid;
  letter-spacing: -0.04rem;

  @include min768 {
    font-size: 72px;

    @media (max-height: 600px) {
      font-size: 52px;
    }
  }
  @include min1280 {
    font-size: 92px;
    line-height: 1;
  
    @media (max-height: 600px) {
      font-size: 80px;
    }
  }
  @include outsideContainer {
    font-size: 98px;
  }
}

h2, .h2 {
  font: 700 32px/1.2 $hagrid;
  letter-spacing: -0.01rem;

  @include min768 {
    line-height: 1.1;
    font-size: 56px;

    @media (max-height: 600px) {
      font-size: 56px * 0.75;
    }
  }
  @include min1280 {
    font-size: 80px;
  
    @media (max-height: 600px) {
      font-size: 80px * 0.75;
    }
  }
}

h3, .h3 {
  font: 700 26px/1.3 $hagrid;
  letter-spacing: -0.01rem;

  @include min768 {
    line-height: 1.1;
    font-size: 36px;

    @media (max-height: 600px) {
      font-size: 36px * 0.75;
    }
  }
  @include min1280 {
    font-size: 64px;
  
    @media (max-height: 600px) {
      font-size: 64px * 0.75;
    }
  }
}

h4, .h4 {
  font: 700 22px/1.3 $hagrid;
  letter-spacing: 0;

  @include min768 {
    line-height: 1.2;
    font-size: 28px;

    @media (max-height: 600px) {
      font-size: 28px * 0.75;
    }
  }
  @include min1280 {
    font-size: 40px;
    line-height: 1.1;
  
    @media (max-height: 600px) {
      font-size: 40px * 0.75;
    }
  }
}

h5, .h5 {
  font: 500 18px/1.4 $hagrid;
  letter-spacing: 0;

  @include min768 {
    font-size: 18px;

    @media (max-height: 600px) {
      font-size: 16px;
    }
  }
  @include min1280 {
    font-size: 24px;
  
    @media (max-height: 600px) {
      font-size: 24px * 0.75;
    }
  }
}

.hand {
  font: 400 28px/1 $jeff;
  letter-spacing: -0.01rem;

  @include min768 {
    letter-spacing: -0.02rem;

    @media (max-height: 600px) {
      font-size: 28px * 0.75;
    }
  }
  @include min1280 {
    font-size: 40px;
  
  
    @media (max-height: 600px) {
      font-size: 40px * 0.75;
    }
  }
}

.small,
small {
  font: 400 12px/1.4 $ibm;

  @include min1280 {
    font-size: 14px;
  }
}

.small-links {
  font: 500 12px/1.4 $nobile;
  letter-spacing: 0.06rem;
}

.text {
  font: 400 18px/1.4 $ibm;
  letter-spacing: -0.02rem;

  @include min768 {
    font: 400 20px/1.5 $ibm;

    @media (max-height: 600px) {
      font-size: 16px;
    }
  }
  @include min1024 {
    font: 400 24px/1.5 $ibm;
  }

  ul + *,
  ol + *,
  p + * {
    margin-top: 24px;

    @include min768 {
      margin-top: 32px;
    }
    @include min1280 {
      margin-top: 40px;
    }
  }
}

.input-text {
  font: 400 16px/1.4 $ibm;

  @include min768 {
    letter-spacing: -0.02rem;
  }
  @include min1024 {
    font: 400 18px/1.4 $ibm;
  }
}

.primitive {
  a {
    color: $orange;
    transition: opacity .4s ease;

    &:hover {
      text-decoration: underline;
      opacity: .6;
    }
  }

  ol, ul {
    & + *,
    ul, ol {
      margin-top: 24px;

      @include min768 {
        margin-top: 32px;

        @media (max-height: 600px) {
          margin-top: 24px;
        }
      }
      @include min1280 {
        margin-top: 40px;
      }
    }

    li {

      & + li {
        margin-top: 16px;

        @media (max-height: 600px) {
          margin-top: 8px;
        }
      }
    }
  }

  ol {
    counter-reset: list;

    li {
      color: $black;
      position: relative;
      padding-left: 40px;
  
      @include min1280 {
        padding-left: 48px;
      }

      &::before {
        content: counter(list) '.';
        counter-increment: list;
        display: block;
        position: absolute;
        left: 0;
        border-radius: 50%;
        color: $orange;
      }
    }
  }

  ul {
    li {
      color: $black;
      position: relative;
      padding-left: 24px;
  
      @include min1280 {
        padding-left: 32px;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: .5em;
        width: .4em;
        height: .4em;
        background-color: $orange;
        border-radius: 50%;

        @media (max-height: 600px) {
         // top: 10px;
        }

        @include min1024 {
          top: .6em;
        }

        @include min1280 {
         // top: .6em;
        }
      }

      ul {
        li::before {
          background-color: transparent;
          width: 4px;
          height: 4px;
          border: 2px solid $orange;
        }
      }
    }
  }
}

@charset "utf-8";

// version 1.0.3
// не менять

@import 'variables';

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, button, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, option, select, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: none;
  // outline:none;

  &::selection {
    background: $orange;
    color: $black;
  }
}

html {
  // height:100%;
  font-family: $defaultText;
  background: $bg;
  color: $defaultTextColor;

  &[lang='en'], &[lang='ru'] {
    font-family: $defaultText;
  }
}
//main{
//  height:100%;
//}


body {
  width: 100%;
  height: 100%;
  font: 400 16px/1.4 $ibm;

  @include min768 {
    letter-spacing: -0.02em;
  }
  @include min1280 {
    font-size: 18px;
  }
}

body, canvas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* stylelint-disable */
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  /* stylelint-enable */
}

canvas {
  font-family: $defaultText;
}

ul, ol {
  list-style: none;
}

a {
  text-decoration: none;

  img {
    border: none;
  }
}

input, textarea, select {
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  border-radius: 0;

  //&:focus {
  //  outline:none;
  //}
}

:invalid, :-moz-submit-invalid, :-moz-ui-invalid {
  box-shadow: none;
}


button {
  display: block;
  font: inherit;
  color: inherit;
  box-sizing: content-box;
  cursor: pointer;
  overflow: visible;
  background: none;

  &:focus {
  //  border: none;
  }
}


.no-hover * {
  pointer-events: none !important;
} // 60 fps


.prevent-scroll {
  overflow: hidden !important;
}

.no-outline {
  * {
    outline: none !important;
  }
}

.defs {
  display: none;
}

// I used hack to fix border on Safari 15+ when images which use loading="lazy" has white border while loading.
// https://github.com/vercel/next.js/issues/40615
@media not all and (min-resolution: 0.001dpcm) {
  img[loading='lazy'] {
    clip-path: inset(0.5px);
  }
}

button,
a {
  outline: none;
  text-decoration: none !important;
}

.button {
  display: inline-block;
  position: relative;

  &.full {
    width: 100%;
  }

  &.large {
    .button-text {
      border-radius: 40px;
      letter-spacing: 0.02em;
      font: 600 14px/1.3 $ibm;
      padding: 15px 32px;

      @include min1280 {
        font-size: 18px;
        padding: 21px 40px;
      }
    }
  }

  &.small {
    .button-text {
      border-radius: 32px;
      letter-spacing: 0.06em;
      font: 600 12px/1.4 $ibm;
      padding: 8px 16px;
    }
  }

  &.orange .button-text {
    background: $orange;
    color: $white;
  }

  &.white .button-text {
    background: $white;
    color: $orange;
  }

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background: $black;
    z-index: -1;
    border-radius: 40px;
  }

  &:hover,
  &:active {
    &.large .button-text {
      transform: translate3d(-6px, -6px, 0);
    }

    &.small .button-text {
      transform: translate3d(-4px, -4px, 0);
    }
  }

  &.disabled {
    pointer-events: none;

    &.orange .button-text {
      background: $orange30;
    }

    &.white .button-text {
      color: $orange30;
    }
  }
}

.button-text {
  text-transform: uppercase;
  text-align: center;
  box-sizing: border-box;
  display: inline-block;
  transition: transform 0.3s $ease;
  will-change: transform;

  & > * {
    display: inline-block;
    background: $white;
    color: $orange;
    font-weight: 400;
    padding: 1px 6px;
    border-radius: 16px;
    margin-left: 8px;
    font-style: normal;

    @include min1280 {
      padding: 3px 8px;
      margin-left: 16px;
    }
  }
}

.button-icon {
  margin-left: 8px;
}

.button-with-icon {
  display: flex;
  align-items: center;
  border-radius: 32px;
  color: $white;
  background-color: $orange;
  padding: 7px 16px 6px;
  transition: background-color 0.3s ease;

  @include min768 {
    padding: 8px 16px 6px;
  }

  span {
    font-family: $nobile;
    font-weight: 500;
    font-size: 10px;
    line-height: 1.4em;
    letter-spacing: 0.06em;

    @include min768 {
      font-size: 12px;
    }
  }

  svg {
    width: 13px;
    height: 13px;
    display: block;
    fill: $white;
  }

  &:hover {
    background-color: transparentize($orange, 0.15);
  }

  &:active {
    background-color: transparentize($orange, 0.3);
  }
}


.button-transparent {
  display: inline-block;
  position: relative;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 32px;
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  padding: 8px 16px;

  @include min1280 {
    display: inline-flex;
    align-items: baseline;
    padding: 7px 16px 5px;
  }

  &--orange {
    border-color: $orange;
    color: $orange;

    svg {
      fill: $orange;
      stroke: $orange;
    }

    &:hover {
      background-color: $orange;
      border-color: $orange;
      color: $white;

      svg {
        fill: $white;
        stroke: $white;
      }
    }
  }

  &--white {
    border-color: $white;
    color: $white;

    svg {
      fill: $orange;
      stroke: $orange;
    }

    &:hover {
      background-color: $white;
      border-color: $white;
      color: $orange;

      svg {
        fill: $orange;
        stroke: $orange;
      }
    }
  }

  &__icon {
    width: 13px;
    min-width: 13px;
    height: 13px;
    min-height: 13px;
    position: relative;

    svg {
      display: block;
      width: 13px;
      height: 13px;
      transition: stroke 0.3s ease;
    }
  }

  &__text {
    display: none;

    @include min1280 {
      display: block;
      color: inherit;
      text-transform: uppercase;
      margin-left: 10px;
    }
  }
}

.button-nav {
  padding: 16px 24px;
  transition: background-color 0.3s ease;
  outline: none;
  border: 1px solid $orange;
  cursor: pointer;

  @include min1280 {
    padding: 24px 32px;
  }

  svg {
    width: 16px;
    height: 16px;
    transition: stroke 0.3s ease;
  }
  
  &.small {
    padding: 11px 17px;
    border-radius: 16px;
    
    svg {
      width: 10px;
      height: 10px;
    }
  }

  &--transparent-orange {
    border-radius: 88px;
    border: 1px solid $orange;

    svg {
      display: block;
      stroke: $orange;
    }

    &:hover {
      background-color: $orange;

      svg {
        stroke: $white;
      }
    }

    &:active {
      background-color: transparentize($orange, 0.15);

      svg {
        stroke: $white;
      }
    }
  }

  &--transparent-white {
    border-radius: 88px;
    border: 1px solid white;

    svg {
      display: block;
      stroke: $white;
    }

    &:hover {
      background-color: $white;

      svg {
        stroke: $orange;
      }
    }

    &:active {
      background-color: transparentize($white, 0.15);

      svg {
        stroke: $orange;
      }
    }
  }
}


.more {
  display: flex;
  justify-content: center;
  margin-top: 32px;

  @include min768 {
    margin-top: 48px;
    
    @media (max-height: 600px) {
      margin-top: 32px;
    }
  }
  @include min1280 {
    margin-top: 64px;

    @media (max-height: 740px) {
      margin-top: 48px;
    }
  }
}


@font-face {
  font-family: 'Hagrid';
  src:
    local('Hagrid-Medium'),
    local('Hagrid Medium'),
    url('./fonts/Hagrid-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Hagrid';
  src:
    local('Hagrid-Extrabold'),
    local('Hagrid Extrabold'),
    url('./fonts/Hagrid-Extrabold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'IBMPlexSans';
  src:
    local('IBMPlexSans-Regular'),
    local('IBMPlexSans Regular'),
    url('./fonts/IBMPlexSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'IBMPlexSans';
  src:
    local('IBMPlexSans-SemiBold'),
    local('IBMPlexSans SemiBold'),
    url('./fonts/IBMPlexSans-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'JeffScript';
  src:
    local('JeffScript'),
    url('./fonts/JeffScript.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Nobile';
  src:
    local('Nobile-Medium'),
    local('Nobile Medium'),
    url('./fonts/Nobile-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
